<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Requisito - Editar</strong>
            <router-link  class="btn btn-light float-right btn-sm"  :to="{ path: '/requisito/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>
                <b-col md="2">
                  <b-form-group label="Tipo:">
                    <b-form-select v-model="requirement.id_module" :options="modules"></b-form-select>
                    <small v-if="errors.id_module"  class="form-text text-danger" >Seleccione un Tipo</small>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Nombre:">
                    <b-form-input type="text" v-model="requirement.name"></b-form-input>
                    <small v-if="errors.name"  class="form-text text-danger" >Ingrese un nombre</small>
                  </b-form-group>
                </b-col>

                <b-col md="5">
                  <b-form-group label="Descripción:">
                    <b-form-input v-model="requirement.description"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Estado:">
                    <b-form-select v-model="requirement.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components

import LoadingComponent from './../pages/Loading'
export default {
  name: "BranchOfficeEdit",
  props: ["id_requirement"],
  components:{
      vSelect,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Requirement',
      role: 3,
      requirement: {
          id_requirement:'',
          id_module:'',
          name:'',
          description:'',
          state:1,
      },
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],
      modules: [],
      errors: {
        id_module:false,
        name: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListModule();
    this.ViewRequirement();
  },
  methods: {
    EditRequirement,
    Validate,
    ViewRequirement,
    ListModule,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function ListModule() {

let me = this;
let url = me.url_base + "module/actives";
me.modules = [{ value: '', text:'- Seleccione una opcion - '}];
axios({
  method: "GET",
  url: url,
  headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
})
  .then(function (response) {
    if (response.data.status == 200) {
      response.data.result.forEach(element => {
        me.modules.push({value: element.id_module, text: element.name });
      });
    }

  });
}

function ViewRequirement() {
  let me = this;
  let id_requirement = je.decrypt(this.id_requirement);
  let url = me.url_base + "requirement/view/"+id_requirement;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.requirement.id_requirement = response.data.result.id_requirement;
        me.requirement.id_module = response.data.result.id_module;
        me.requirement.name = response.data.result.name;
        me.requirement.description = response.data.result.description;
        me.requirement.state = response.data.result.state;
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function EditRequirement() {
  let me = this;
  let url = me.url_base + "requirement/edit";
  let data = me.requirement;
  me.isLoading = true;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.name = this.requirement.name.length == 0 ? true : false;

  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar el requisito ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditRequirement();
    }
  });
}
</script>
